import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import '@angular/common/locales/global/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StdModule, NtsAppRootModule, NtsBootService } from '@nts/std';
import { environment } from 'src/environments/environment';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT, unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';
import { FormsModule } from '@angular/forms';
import * as moment_ from 'moment';
import * as Highcharts from 'highcharts';
import { ServiceWorkerModule } from '@angular/service-worker';

declare const require: any;
const jstz = require('jstimezonedetect');
const moment = moment_;


export function initializerHighchartsFactoryFunction() {
  // Do initing of services that is required before app loads
  // NOTE: this factory needs to return a function (that then returns a promise)
  return async () => {

    Highcharts.setOptions({
      time: {
        timezone: jstz.determine().name(),
        timezoneOffset: -moment().utcOffset()
      },
      lang: {
        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the months
         * names in abbreviated form. Corresponds to the `%b` format in
         * `Highcharts.dateFormat()`.
         */
        shortMonths: moment.localeData().monthsShort(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) Short week days, starting
         * Sunday. If not specified, Highcharts uses the first three letters of the
         * `lang.weekdays` option.
         */
        shortWeekdays: moment.localeData().weekdaysShort(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the weekday
         * names.
         */
        weekdays: moment.localeData().weekdays(),

        /**
         * (Highcharts, Highstock, Highmaps, Gantt) An array containing the months
         * names. Corresponds to the `%B` format in `Highcharts.dateFormat()`.
         */
        months: moment.localeData().months()
      },
    });
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    StdModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerHighchartsFactoryFunction,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it' },
    ...StdModule.getRootProviders(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService
  ) {
    super(bootService);
  }
}
