import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [
  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'software',
        () => import('./software/software.module').then(m => m.SoftwareModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'softwareprice',
        () => import('./software-price/software-price.module').then(m => m.SoftwarePriceModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironment',
        () => import('./server-environment/server-environment.module').then(m => m.ServerEnvironmentModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironmentbill',
        () => import('./server-environment-bill/server-environment-bill.module').then(m => m.ServerEnvironmentBillModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironmentfee',
        () => import('./server-environment-fee/server-environment-fee.module').then(m => m.ServerEnvironmentFeeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironmentnote',
        () => import('./server-environment-note/server-environment-note.module').then(m => m.ServerEnvironmentNoteModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironmentforcedprice',
        () => import('./server-environment-forced-price/server-environment-forced-price.module').then(m => m.ServerEnvironmentForcedPriceModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverenvironmentdocument',
        () => import('./server-environment-document/server-environment-document.module').then(m => m.ServerEnvironmentDocumentModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'imagevm',
        () => import('./image-vm-aggregate/image-vm-aggregate.module').then(m => m.ImageVMAggregateModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'imagevmprice',
        () => import('./image-vm-price-aggregate/image-vm-price-aggregate.module').then(m => m.ImageVMPriceAggregateModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'servicevm',
        () => import('./service-vm/service-vm.module').then(m => m.ServiceVMModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'servicevmprice',
        () => import('./service-vm-price/service-vm-price.module').then(m => m.ServiceVMPriceModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'virtualmachinetype',
        () => import('./virtual-machine-type/virtual-machine-type.module').then(m => m.VirtualMachineTypeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serverconfiguration',
        () => import('./server-configuration/server-configuration.module').then(m => m.ServerConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'instancevm',
        () => import('./instance-vm/instance-vm.module').then(m => m.InstanceVMModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'instancevmnote',
        () => import('./instance-vm-note/instance-vm-note.module').then(m => m.InstanceVMNoteModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'proxmoxconfig',
        () => import('./proxmox-config/proxmox-config.module').then(m => m.ProxmoxConfigModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'priceconfig',
        () => import('./price-config/price-config.module').then(m => m.PriceConfigModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'profitchecker',
        () => import('./profit-checker/profit-checker.module').then(m => m.ProfitCheckerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'pricelistviewer',
        () => import('./price-list-viewer/price-list-viewer.module').then(m => m.PriceListViewerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'legalagreement',
        () => import('./legal-agreement/legal-agreement.module').then(m => m.LegalAgreementModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'technicalconfirmation',
        () => import('./technical-confirmation/technical-confirmation.module').then(m => m.TechnicalConfirmationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'priceconfirmation',
        () => import('./price-confirmation/price-confirmation.module').then(m => m.PriceConfirmationModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // #region rotte con autenticazione con container custom
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: 'manage/wizardvm',
        data: {
          title
        },
        loadChildren: () => import('./wizard-vm/wizard-vm.module').then(m => m.WizardVMModule)
      },
      {
        path: 'manage/pricesimulator',
        data: {
          title
        },
        loadChildren: () => import('./price-simulator/price-simulator.module').then(m => m.PriceSimulatorModule)
      },
      {
        path: 'manage/serverenvironmentchange',
        data: {
          title
        },
        loadChildren: () => import('./server-environment-change/server-environment-change.module').then(m => m.ServerEnvironmentChangeModule)
      },
    ], () => import('@nts/std').then(mod => mod.EmptyContainerWithToastComponent),
  ),
  // #endregion rotte con autenticazione con container custom

  // #region rotte per indice maschere
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte indice maschere

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
